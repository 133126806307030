<template>
<v-container>
    <v-col class="mb-4">
        <h1 class="display-2 font-weight-bold mb-3">Отлики по запросам</h1>
    </v-col>
    <v-col>
        <v-row>
            <v-card>
                <v-card-title>
                    <v-text-field v-model="search" append-icon="mdi-magnify" label="Поиск" single-line hide-details></v-text-field>
                </v-card-title>
                <v-data-table :headers="headers" :items="country" :items-per-page="10" :search="search" class="elevation-1">
                    <template v-slot:item.id_mess="{ item }">
                        <v-btn :href="'https://t.me/itrequests/'+item.id_mess" target="_blank" class="btn_read" title="Перейти в телеграм" style="margin-top:2px; margin-bottom: 3px">
                            Перейти
                        </v-btn>

                    </template>
                    <template v-slot:item.activ="{ item }">
                      <v-select :items="['Закрыт', 'Активен']" v-model="item.actStr" @Change="read(item.id)" label="Статус"></v-select>
                        
                    </template>
                </v-data-table>
            </v-card>
        </v-row>

    </v-col>
</v-container>
</template>

<script>
export default {
    data: () => ({
      search:'',
      id: 0,
        headers: [{
                text: "id",
                value: "id",
            },
            {
                text: "Менеджер",
                value: "fio"
            },
            {
                text: "Инфо о проекте",
                value: "lvl"
            },
            {
                text: "Локация",
                value: "Location"
            },
            {
                text: "Роль",
                value: "title"
            },
            {
                text: "Уровень",
                value: "level"
            },
            {
                text: "Рейт",
                value: "rate"
            },
            {
                text: "Ссылка",
                value: "id_mess"
            },
            {
                text: "Дата Создания",
                value: "dateCreate"
            },
            {
                text: "Фирма",
                value: "firms"
            },
            {
                text: "Статус",
                value: "activ"
            },
        ],
    }),
    name: "answerRequest",
    mounted() {
        this.$store.dispatch("getAnswerRequest");
    },
    methods: {
      read(t){
        console.log(t)
      }
    },
    computed: {
        country() {
            return this.$store.getters.ListAnswer;
        },

    },
};
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped></style>
