<template>
  <v-container>
    <v-alert density="compact" color="#D9d9d9" type="info" title="Alert title">
      Уважаемый пользователь! Сервис в процессе постоянной доработки, могут быть ошибки - это нормально, скоро починим.
    </v-alert>
    <v-col class="mb-4">
      <h1 class="display-2 font-weight-bold mb-3">Регистрация</h1>
    </v-col>
    <v-row>
      <v-col cols="4" class="mb-4">

      </v-col>
      <v-col cols="4" class="mb-4">
        <v-form ref="form" v-model="valid" lazy-validation>
          <element-loading :active="onLoadData" spinner="spinner" color="#FF6700" />
          <!-- <v-select :items="items" label="Ваша компания*" v-model="tp" required @change="clearForm">
          </v-select> -->
          <v-select :items="country" label="Страна*" v-model="grag" required @change="clearForm" :rules="SelectRules">
          </v-select>
          <v-select v-if="grag != 'Россия' && grag != ''" :items="listTypeFirm"
            label="Форма организации (например, Акционерное Общество)*" v-model="tcom" required @change="clearForm">
          </v-select>
          <!-- <v-text-field v-if="grag != 'Россия'" v-model="tcom" :rules="nameRules"
            label="Форма организации (например, Акционерное Общество)*" required></v-text-field> -->
          <v-text-field v-if="grag != 'Россия' && grag != ''" v-model="name" :rules="nameRules"
            label="Название вашей компании (как в документах на Русском языке)*" required></v-text-field>

          <v-text-field v-if="grag == 'Россия' && grag != ''" v-model="inn" :rules="innRules" @change="onGetFirm"
            label="ИНН вашей компании*" required></v-text-field>
          <v-alert dense border="left" type="warning" v-if="validInn.atv">
            {{ validInn.msg }}
          </v-alert>
          <v-text-field v-model="telegram" :rules="TextRules"
            label="Telegram для связи и активации кабинета, в формате @account" required></v-text-field>
            <v-text-field v-model="phone"
            label="Телефон для связи"></v-text-field>

          <v-text-field v-model="email" :rules="emailRules" label="E-mail" @change="onGetInfoEmail"
            required></v-text-field>
          <v-alert dense border="left" type="warning" v-if="validEmail">
            На данную почту уже зарегистрирована фирма!
          </v-alert>
          <v-text-field v-model="pass" :rules="passRules" label="пароль*" required type="password"></v-text-field>

          <v-text-field v-model="passNext" :rules="passRulesNext" label="повторите пароль*" type="password" required
            v-on:keyup.enter="validate"></v-text-field>

          <v-checkbox v-model="checkbox" required>
            <template v-slot:label>
              <div>
                Нажимая "Зарегистрироваться", вы соглашаетесь с
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <a target="_blank" href="/info/rules/site" @click.stop v-on="on">
                      Правилами обработки и использования персональных данных
                    </a>
                  </template>
                  Открыть в новой вкладке
                </v-tooltip>
                , а также с
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <a target="_blank" href="/info/rules/peson" @click.stop v-on="on">
                      Условиями использования сайта.
                    </a>
                  </template>
                  Открыть в новой вкладке
                </v-tooltip>
              </div>
            </template>
          </v-checkbox>


          <v-row>
            <!-- <v-col cols="12">
              <v-btn :disabled="!valid" color="success" class="mr-4" v-on:keyup.enter="validate" v-on:click="validate">
                Зарегистрироваться
              </v-btn>
            </v-col> -->
            <v-col cols="12">
              <v-btn color="#FFE816" class="mr-4" v-on:keyup.enter="validate" v-on:click="validate"> Далее </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-btn color="#FFE816" to="/"> Войти </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
      <v-col cols="4" class="mb-4"> </v-col>
    </v-row>
    <!-- <v-row>
      <v-col cols="12">
        <v-btn color="primary" to="/"> Войти </v-btn>
      </v-col>
    </v-row> -->
  </v-container>
</template>

<script>
export default {
  data: () => ({
    valid: true,
    validInn: {
      atv: false,
      msg: "",
    },
    validEmail: false,
    innValid: false,
    name: "",
    listTypeFirm: ['Общество с ограниченной ответственностью', 'Открытое акционерное общество', 'Закрытое акционерное общество'],
    inn: "",
    grag: "",
    pass: "",
    tp: "Предлагает специалистов",
    offname: "",
    utm_c: "",
    utm_m: "",
    utm_s: "",
    kpp: "",
    ogrn: "",
    telegram: "",
    phone: "",
    colspec: 0,
    paymiddle: 0,
    paysenior: 0,
    fio: "",
    pos: "",
    tcom: "",
    adrr: "",

    items: ["Предлагает специалистов", "Арендует специалистов"],
    passNext: "",
    nameRules: [(v) => !!v || "Название фирмы должно быть заполнено"],
    innRules: [
      (v) => !!v || "инн должно быть заполнено",
      (v) =>
        (v && (v.length == 10 || v.length == 12)) ||
        "ИНН должнет состоять из 10 или 12 цифр",
    ],
    email: "",
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    passRules: [
      (v) => !!v || "Пароль должен быть заполнен",
      (v) =>
        (v && v.length >= 8) || "Пароль должен состоять от 8 символов и более",
    ],
    passRulesNext: [
      (v) => !!v || "Пароль должен быть заполнен",
      (v) =>
        (v && v.length >= 8) || "Пароль должен состоять от 8 символов и более",
    ],
    TextRules: [
      (v) => !!v || "Пароль должен быть заполнен",
    ],
    SelectRules: [
      (v) => !!v || "Страна должна быть выбрана",
    ],
    select: null,
    checkbox: false,
  }),
  name: "Register",
  computed: {
    country() {
      return this.$store.getters.getListLocation;
    },
    onLoadData() {
      return this.$store.getters.getOnLoad;
    },
  },
  mounted() {
    this.$store.dispatch("getLocationList");
    if (this.$route.query != []) {
      this.utm_c = this.$route.query.utm_campaign;
      this.utm_m = this.$route.query.utm_medium;
      this.utm_s = this.$route.query.utm_source;
    }


  },
  methods: {
    validate() {
      //this.valid = false;
      this.$refs.form.validate();
      if (this.innValid == false) {
        if (this.grag != "" && ((this.grag != 'Россия' && this.inn == "") || (this.grag == 'Россия' && this.inn != "")) && this.email != "" && this.pass != "" && this.passNext != "" && this.checkbox != "" && this.telegram != "") {
          let f = {
            name: this.name,
            inn: this.inn,
            ps: this.pass,
            eml: this.email,
            grag: this.grag,
            offnm: this.offname,
            kpp: this.kpp,
            orgn: this.ogrn,
            fio: this.fio,
            pos: this.pos,
            tcom: this.tcom,
            tp: this.tp,
            adrr: this.adrr,
            tel: this.telegram,
            utm_c: this.utm_c,
            utm_m: this.utm_m,
            utm_s: this.utm_s,
            phone: this.phone,
          };
          console.log(f);
          this.$store.dispatch("saveCompanyPageOne", f);
          this.$router.push('/quiz')
        }
      }


    },

    reset() {
      this.$refs.form.reset();
    },
    onGetFirm() {
      this.$store.dispatch("findInnBase", this.inn).then(res => {
        this.validInn.atv = false;
        if (res == true) {
          this.validInn.atv = true
          this.validInn.msg = "Hа данный инн уже зарегистрирован пользователь";
        } else {
          if (this.grag == 'Россия') {
            var url =
              "https://suggestions.dadata.ru/suggestions/api/4_1/rs/findById/party";
            //var token = "326c0f7d4ced81baa0d95a35cceda204bc255423";
            var token = "0c25c98c905ebd8dbfd7308961885acc6c000c22";
            var query = this.inn;

            var options = {
              method: "POST",
              mode: "cors",
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: "Token " + token,
              },
              body: JSON.stringify({ query: query }),
            };

            fetch(url, options)
              .then((response) => response.text())
              .then((result) => {
                let r = JSON.parse(result);
                if (r.suggestions.length == 0) {
                  this.validInn.atv = true
                  this.validInn.msg = "Данного ИНН не существует среди юридических лиц РФ";
                  this.innValid = true;
                } else {
                  if (r.suggestions[0].data.state.status == "ACTIVE") {
                    console.log(r.suggestions[0].data)
                    this.innValid = false;
                    this.name = r.suggestions[0].data.name.short_with_opf;
                    this.tcom = r.suggestions[0].data.opf.full;
                    this.email = r.suggestions[0].data.emails;
                    this.offname = r.suggestions[0].data.name.full_with_opf;
                    this.kpp = r.suggestions[0].data.kpp;
                    this.ogrn = r.suggestions[0].data.ogrn;
                    if (this.inn.length == 12) {
                      this.fio = r.suggestions[0].data.name.full;
                      this.pos = 'Директор';
                    } else {
                      this.fio = r.suggestions[0].data.management.name;
                      this.pos = r.suggestions[0].data.management.post;

                    }
                    this.adrr = r.suggestions[0].data.address.unrestricted_value;
                  } else {
                    this.validInn.atv = true
                    this.validInn.msg = "На данный инн невозможно зарегистрировать аккаут";
                  }
                }


              })
              .catch((error) => console.log("error", error));
          } else {
            this.name = "";
            this.tcom = "";
            this.email = "";
            this.offname = "";
            this.kpp = "";
            this.ogrn = "";
            this.fio = "";
            this.pos = "";
            this.tcom = "";
          }
        }
      });

    },
    clearForm() {
      this.name = "";
      this.email = "";
      this.offname = "";
      this.kpp = "";
      this.ogrn = "";
      this.fio = "";
      this.pos = "";
      this.inn = "";
      this.tcom = "";
      if (this.grag == 'Россия') {
        this.listTypeFirm = [
          'Общество с дополнительной отвественностью',
          'Общество с ограниченой отвественностью',
          'Открытое акционерное общество',
          'Закрытое акционерное общество',
          'Индивидуальный предприниматель',
          'Частное унитарное предприятие',
          'Товарищество с ограниченой отвественностью',
          'Товарищество с дополнительной отвественостью',
          'Командитное товарищество',
          'Общественное объединение',
          'Полное товарищество',
          'Производственный кооператив',
          'Семейная компания',
          'Унитарное предприятие',
        ]

      }
      if (this.grag == 'Армения') {
        this.listTypeFirm = [

          'Общество с ограниченой отвественностью',
          'Открытое акционерное общество',
          'Закрытое акционерное общество',
          'Индивидуальный предприниматель',

        ]

      }
      if (this.grag == 'Беларусь') {
        this.listTypeFirm = [
          'Открытое акционерное общество',
          'Закрытое акционерное общество',
          'Индивидуальный предприниматель',
          'Частное унитарное предприятие',
          'Общество с ограниченной ответственностью',
        ]

      }
      if (this.grag == 'Казахстан') {
        this.listTypeFirm = [
          'Общество с ограниченой отвественностью',
          'Открытое акционерное общество',
          'Закрытое акционерное общество',
          'Индивидуальный предприниматель',
          'Товарищество с ограниченой отвественностью',
          'Товарищество с дополнительной отвественостью',
          'Командитное товарищество',
          'Общественное объединение',
          'Полное товарищество',
          'Производственный кооператив',
          'Государственное предприятие',
        ]

      }
      if (this.grag == 'Киргизия') {
        this.listTypeFirm = [
          'Общество с дополнительной отвественностью',
          'Общество с ограниченой отвественностью',
          'Открытое акционерное общество',
          'Закрытое акционерное общество',
          'Индивидуальный предприниматель',
          'Командитное товарищество',
          'Полное товарищество',
        ]

      }
      if (this.grag == 'Молдавия') {
        this.listTypeFirm = [
          'Общество с ограниченой отвественностью',
          'Открытое акционерное общество',
          'Закрытое акционерное общество',
          'Индивидуальный предприниматель',
          'Командитное товарищество',
          'Полное товарищество',
          'Производственный кооператив',
          'Арендное предприятие',
          'Государственные учреждение',
          'Предпринимательский кооператив',
        ]

      }
      if (this.grag == 'Таджикистан') {
        this.listTypeFirm = [

          'Общество с ограниченой отвественностью',
          'Открытое акционерное общество',
          'Закрытое акционерное общество',

        ]

      }
      if (this.grag == 'Узбекистан') {
        this.listTypeFirm = [
          'Общество с дополнительной отвественностью',
          'Общество с ограниченой отвественностью',
          'Открытое акционерное общество',
          'Закрытое акционерное общество',
          'Индивидуальный предприниматель',
          'Совместное предприятие',
          'Семейная компания',
          'Унитарное предприятие',
          'Частная компания',
        ]

      }

    },
    onGetInfoEmail() {
      this.validEmail = false
      this.$store.dispatch("findEmailFirm", this.email).then(res => {
        if (res == true) {
          this.validEmail = true
        }
      });
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    matchingPasswords() {
      if (this.pass === this.passNext) {
        return "true";
      } else {
        return "Пароли должны совпадать!";
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
